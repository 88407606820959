import { useCallback } from 'react';
import useGeoInfo from 'src/hooks/use-geo-info';
import {
  ContentAvailability,
  ContentAvailabilityNode,
  getContentAvailability,
} from 'src/utilities/content-availability-helpers';

/**
 * Custom hook that returns a function that can check if a node is blocked in the current geo country
 *
 * @example
 * ```tsx
 * const { isGeoBlockedNode } = useGeoBlocking();
 * const isBlocked = isGeoBlockedNode(node);
 * ```
 */
export function useGeoBlocking() {
  const { data: geoInfo } = useGeoInfo();
  const isGeoBlockedNode = useCallback(
    (node: ContentAvailabilityNode): boolean =>
      getContentAvailability(node, { countryCode: geoInfo?.countryCode }) === ContentAvailability.GeoBlocked,
    [geoInfo?.countryCode],
  );
  return {
    isGeoBlockedNode,
  };
}
