import type { SVGProps } from 'react';
const SvgPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M18.594 10.629 8.969 4.942C8.176 4.477 7 4.942 7 6.062v11.376c0 1.039 1.094 1.668 1.969 1.148l9.625-5.687a1.33 1.33 0 0 0 0-2.27"
    />
  </svg>
);
export default SvgPlay;
