import { useCallback } from 'react';
import useSWR from 'swr';
import { FavoriteByContentIdQuery } from 'generated/graphql';
import usePermissions from 'src/hooks/use-permissions';
import useSdk from 'src/hooks/use-sdk';
import { trackEvent } from 'src/tracking';
import { FavoritableNode } from 'src/utilities/favorite-helpers';

/**
 * A hook to handle the favoriting functionality of a given node.
 */
export function useFavorite(node: FavoritableNode): {
  isFavorite: boolean;
  isLoading: boolean;
  toggleFavorite: () => Promise<void>;
} {
  // Get the SDK to talk to the API
  const sdk = useSdk();
  // Get permissions to check if the user can favorite
  const { checkCanFavoriteMedia, openRequiredModalsToFavoriteMedia } = usePermissions();
  // Check if the user can favorite
  const canFavorite = checkCanFavoriteMedia();

  // Use SWR to fetch the favorite status of the node when the user can favorite
  const { data, mutate, isLoading } = useSWR(canFavorite ? `favorite/${node.id}` : undefined, () =>
    sdk.favoriteByContentId({ contentId: node.id }),
  );

  // If `favoriteByContentId` is not `undefined`, the node is favorited
  const isFavorite = Boolean(data?.favoriteByContentId);

  // Create a function to toggle the favorite status of the node
  const toggleFavorite = useCallback(async () => {
    // Create the params for the SDK call
    const params = { input: { contentId: node.id } };
    // Create the optimistic data to update the SWR cache
    const optimisticData: FavoriteByContentIdQuery = {
      __typename: 'RootQueryType',
      favoriteByContentId: isFavorite ? undefined : { __typename: 'Favorite', id: node.id },
    };
    // Create a function to toggle the favorite status based on the `favorite` param
    const toggleFavorite = isFavorite ? () => sdk.removeFavoriteByContentId(params) : () => sdk.addFavorite(params);

    // Show the required modals if the user doesn't have the required permissions to favorite
    if (!canFavorite) {
      openRequiredModalsToFavoriteMedia();
      return;
    }

    // Mutate the SWR cache by calling the `toggleFavorite` function and optimistically updating the cache
    // After a successful mutation, the SWR cache will be revalidated by calling the original fetch function
    // When the mutation fails, the SWR cache will be rolled back to the previous state instead
    await mutate(
      async () => {
        // Update the favorite status in the API
        await toggleFavorite();
        // Track the event in analytics
        trackEvent({ eventName: isFavorite ? 'FavoriteRemoved' : 'FavoriteAdded', contentId: node.id });
        // Return the optimistic data to update the SWR cache
        return optimisticData;
      },
      {
        // Pass the optimistic data so the UI can update immediately
        optimisticData,
      },
    );
  }, [canFavorite, node.id, isFavorite, mutate, openRequiredModalsToFavoriteMedia, sdk]);

  return {
    isFavorite,
    isLoading,
    toggleFavorite,
  };
}
