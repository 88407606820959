import type { SVGProps } from 'react';
const SvgPlayOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m18.25 10.719-11-6.5C6.344 3.687 5 4.219 5 5.5v13c0 1.188 1.25 1.906 2.25 1.313l11-6.5c.969-.594.969-2 0-2.594M6.5 18.188V5.844c0-.157.156-.25.281-.157l10.438 6.157c.125.094.125.25 0 .344L6.78 18.344c-.125.062-.281 0-.281-.157"
    />
  </svg>
);
export default SvgPlayOutline;
