import type { SVGProps } from 'react';
const SvgShare = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="currentColor">
      <path d="m9.374 5.818 2.414-2.728c.107-.12.32-.12.427 0l2.414 2.728c.196.22.054.581-.213.601h-1.704v7.062c0 .2-.142.36-.302.34h-.817c-.16.02-.302-.14-.302-.34V6.419H9.587c-.285 0-.409-.38-.213-.601" />
      <path d="M7.58 8.295h2.813v1.361H7.73c-.1 0-.15.085-.15.17v7.518c0 .113.075.17.15.17h8.54c.074-.001.149-.058.149-.17V9.826c0-.085-.05-.17-.151-.17h-2.662V8.295h2.813c.678 0 1.205.624 1.205 1.361v7.858c0 .764-.525 1.359-1.201 1.361H7.58c-.678 0-1.205-.596-1.205-1.361V9.656c0-.737.527-1.361 1.205-1.361" />
    </g>
  </svg>
);
export default SvgShare;
