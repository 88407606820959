import type { SVGProps } from 'react';
const SvgReminderOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M18.719 15.344c-.594-.656-1.75-1.625-1.75-4.844 0-2.406-1.688-4.344-4-4.844V5c0-.531-.438-1-.969-1-.562 0-1 .469-1 1v.656c-2.312.5-4 2.438-4 4.844 0 3.219-1.156 4.188-1.75 4.844-.187.187-.281.437-.25.656 0 .531.375 1 1 1h11.969c.625 0 1-.469 1.031-1a.95.95 0 0 0-.281-.656M7.094 15.5c.656-.844 1.375-2.312 1.406-4.969V10.5C8.5 8.594 10.063 7 12 7c1.906 0 3.5 1.594 3.5 3.5 0 .031-.031.031-.031.031.031 2.656.75 4.125 1.406 4.969zM12 20c1.094 0 1.969-.875 1.969-2H10c0 1.125.875 2 2 2"
    />
  </svg>
);
export default SvgReminderOutline;
