import type { SVGProps } from 'react';
const SvgMore = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M13.563 11.625c0-.83-.733-1.562-1.563-1.562-.879 0-1.562.732-1.562 1.562 0 .879.683 1.563 1.562 1.563.83 0 1.563-.684 1.563-1.563m4.296-1.562c-.879 0-1.562.732-1.562 1.562 0 .879.684 1.563 1.562 1.563.83 0 1.563-.684 1.563-1.563 0-.83-.732-1.562-1.563-1.562m-11.718 0c-.88 0-1.563.732-1.563 1.562 0 .879.684 1.563 1.563 1.563.83 0 1.562-.684 1.562-1.563 0-.83-.732-1.562-1.562-1.562"
    />
  </svg>
);
export default SvgMore;
