import type { SVGProps } from 'react';
const SvgPause = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M9.813 17.848c.71 0 1.312-.575 1.312-1.313V6.91a1.33 1.33 0 0 0-1.312-1.312H7.187c-.738 0-1.312.601-1.312 1.312v9.625c0 .738.574 1.313 1.313 1.313zm8.312-1.313V6.91a1.33 1.33 0 0 0-1.312-1.312h-2.625c-.739 0-1.313.601-1.313 1.312v9.625c0 .738.574 1.313 1.313 1.313h2.624c.711 0 1.313-.575 1.313-1.313"
    />
  </svg>
);
export default SvgPause;
