import FavoriteIcon from '@stageplus/icons/react/favorite';
import FavoriteOutlineIcon from '@stageplus/icons/react/favorite-outline';
import { ButtonIconRound, ButtonIconWithBorder } from 'src/components/buttons/icon-button';
import useTranslate from 'src/hooks/use-translate';

type FavoriteButtonProps = {
  /**
   * Whether the current item is favorited or not.
   */
  isActive: boolean;
  /**
   * Callback to be called when the button is clicked.
   */
  onClick: () => void;
  /**
   * Whether to render the button with a border or not.
   */
  border?: boolean;
};

/**
 * A React component that renders a button to add/remove a favorite based on the `isActive` prop.
 */
export default function FavoriteButton({ isActive, onClick, border = false }: FavoriteButtonProps) {
  const t = useTranslate();
  const ButtonIcon = border ? ButtonIconWithBorder : ButtonIconRound;

  return (
    <ButtonIcon
      icon={isActive ? <FavoriteIcon className="size-6" /> : <FavoriteOutlineIcon className="size-6" />}
      title={isActive ? t('contextmenu__remove_favorite') : t('contextmenu__add_favorite')}
      onClick={onClick}
      dataTest="favorite-button"
    />
  );
}
