import { useCallback } from 'react';
import PauseIcon from '@stageplus/icons/react/pause';
import PlayIcon from '@stageplus/icons/react/play';
import { ButtonIconRound, ButtonBG, ButtonIconOnly } from 'src/components/buttons/icon-button';
import usePermissions from 'src/hooks/use-permissions';
import { usePlayerUrl } from 'src/hooks/use-player-url';
import useTranslate from 'src/hooks/use-translate';
import { usePlayback } from 'src/state/playback';
import { useVideoContext } from 'src/state/video';
import { trackEvent } from 'src/tracking';
import { PlayableMediaCompact } from 'src/types';
import { setTimingMark } from 'src/utilities/measure';

type PlayButtonProps = {
  media: PlayableMediaCompact;
  // button switches to pause automatically if the video is playing, but it also can be overridden with this prop
  playing?: boolean;
  bg?: ButtonBG;
  disabled?: boolean;
};

/**
 * A play button that can be used to start playback, resume play or pause a video or audio.
 * the button will check if the user has the permissions to play the content automatically.
 * the pause state will be activated automatically unless you tell the button explicitly which state it should be in.
 * @component
 */
const PlayButton = ({ playing, media, bg, disabled }: PlayButtonProps) => {
  const t = useTranslate();
  // access the <video> tag methods
  const { play, pause, load, isPlaying } = useVideoContext();

  // check if user is allowed to play the stream
  const { checkCanPlayMedia, openRequiredModalsToPlayMedia } = usePermissions();

  // access the playback state
  const { isCurrentMedia } = usePlayback();
  // check if the current media is the current one loaded in the player,
  // note: in case e.g. of the TrailerVideo the id could be the same but the media type will be different
  const thisMediaIsActive = isCurrentMedia(media);
  // in some cases we might need to control the play/pause state from outside,
  // e.g. when multiple play buttons displayed for different tracks
  const activePlayback = typeof playing === 'boolean' ? playing : isPlaying && thisMediaIsActive;

  // player URL operations
  const { activatePlayerUrl } = usePlayerUrl();

  // handle play button clicks
  const playHandler = useCallback(() => {
    if (!media) {
      return;
    }
    // track the play button click
    trackEvent({ eventName: 'PlayerControlsPlay' });
    // check if the user is allowed to play content based on the first stream and their account permissions
    if (!checkCanPlayMedia(media)) {
      openRequiredModalsToPlayMedia(media);
      return;
    }
    // if the video is already loaded, lets just try to play it
    if (thisMediaIsActive) {
      play();
      return;
    } else {
      // Important: synchronously call a method on <video> so the autoplay is enabled on mobile
      load();
      // log the player click timing for the performance tools
      setTimingMark('playerRequested');
      // we go to the player deep link URL now, playback will be initialized there automatically
      void activatePlayerUrl(media);
    }
  }, [media, checkCanPlayMedia, thisMediaIsActive, openRequiredModalsToPlayMedia, play, load, activatePlayerUrl]);
  // handle pause clicks
  const pauseHandler = useCallback(() => {
    pause();
    // Note [29-06-2023]: Disabled the pause button tracking for now, as requested by the analytics team
    // track the pause button click
    // trackEvent({ eventName: 'PlayerControlsPause' });
  }, [pause]);

  return (
    <ButtonIconRound
      onClick={() => (activePlayback ? pauseHandler() : playHandler())}
      disabled={disabled}
      className="size-11"
      title={activePlayback ? t('player__button_pause') : t('player__button_play')}
      dataTest={activePlayback ? 'pause-button' : 'play-button'}
      icon={activePlayback ? <PauseIcon className="size-6" /> : <PlayIcon className="size-6" />}
      bg={bg}
    />
  );
};

type PlayButtonIconOnlyProps = {
  playing: boolean;
  onPlay: () => void;
  onPause: () => void;
};

/**
 * A simple play button that can be used to start playback, resume play or pause a video or audio.
 * note that differently from the `PlayButton`, it requires the playing state and onClick handlers to be passed in.
 */
export const PlayButtonIconOnly = ({ playing, onPlay, onPause }: PlayButtonIconOnlyProps) => {
  const t = useTranslate();
  return (
    <ButtonIconOnly
      onClick={() => (playing ? onPause() : onPlay())}
      className="size-8"
      title={playing ? t('player__button_pause') : t('player__button_play')}
      dataTest={playing ? 'pause-button-transparent' : 'play-button-transparent'}
      icon={playing ? <PauseIcon /> : <PlayIcon />}
      active={true}
    />
  );
};

export default PlayButton;
