import { AnchorHTMLAttributes, useMemo } from 'react';
import Link from 'next/link';
import { VodConcert, LiveConcert, Video, Album } from 'generated/graphql';
import usePermissions from 'src/hooks/use-permissions';
import { TrailerVideo } from 'src/types';
import { setTimingMark } from 'src/utilities/measure';
import { getPlayerPath } from 'src/utilities/url-helpers';

type SitePlayerLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  mediaNode:
    | Pick<VodConcert | Video | LiveConcert, '__typename' | 'publicationLevel' | 'id'>
    | Pick<Album, '__typename' | 'id'>
    | Pick<TrailerVideo, '__typename' | 'id' | 'stream'>;
  trackId?: string | null;
  timeSeconds?: number;
  disabled?: boolean;
};

/**
 * A link to the player for a given video or audio node. The link will check if the user has permissions to view the video
 * and if not, it will display a modal window with a message. You can pass any HTMLAnchorElement attributes and they will be applied on the link
 * @param mediaNode - the video or audio node to link to
 * @param trackId - the track or performance id to link to
 * @param timeSeconds - seconds to link to in the stream
 * @param disabled - in some cases the link can be disabled
 * @returns
 */
const SitePlayerLink = ({ mediaNode, trackId, timeSeconds, children, disabled, ...rest }: SitePlayerLinkProps) => {
  const href = useMemo(() => getPlayerPath(mediaNode, trackId, timeSeconds), [mediaNode, trackId, timeSeconds]);
  const { checkCanPlayMedia, openRequiredModalsToPlayMedia } = usePermissions();
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    // check if user is allowed to play the stream, when called, it will open an auth modal if needed
    if (!checkCanPlayMedia(mediaNode)) {
      // prevent the link from being opened if the user has no permissions
      event.preventDefault();
      // open the auth modal
      openRequiredModalsToPlayMedia(mediaNode);
    }
    // log the player click timing for the performance tools
    setTimingMark('playerRequested');
    // otherwise, the link will be opened
  };
  // if the link is disabled, we won't add the onClick handler or use Link component
  if (disabled) {
    return <a {...rest}>{children}</a>;
  }

  return (
    <Link href={href} prefetch={false} {...rest} onClick={handleClick} rel="nofollow">
      {children}
    </Link>
  );
};

export default SitePlayerLink;
